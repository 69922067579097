import React from 'react';

function OptoutPiano() {
  return (
    <div>
      <p>
        Les cookies Piano Analytics (AT Internet) sont exemptés de la nécessité d&apos;obtenir le consentement, comme
        indiqué dans l’Arrêt CNIL n°2020-091, dans la mesure où ils sont strictement nécessaires au bon fonctionnement
        du site. Vous pouvez refuser le traitement de vos données personnelles de navigation en activant cette option.
        Veuillez noter qu’en cliquant sur le bouton suivant, nous ne pourrons plus mesurer et améliorer nos sites de
        manière optimale.
      </p>
      <button id="optout-piano" className="btn btn--primary">
        Refuser
      </button>
    </div>
  );
}

export default OptoutPiano;
