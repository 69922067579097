// @flow

import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import type { Saga } from 'redux-saga';
import { takeLatest, all, select, take } from 'redux-saga/effects';
import type { GeneratorType } from 'sagas/root';

import { hitTagsArray } from 'constants/hitTagsConstant';
import type { hitTagsArrayType } from 'constants/hitTagsConstant';

import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_CLUB_SUCCESS } from 'actions/clubActions';
import { FETCH_CLUBS_SUCCESS } from 'actions/clubsActions';
import { FETCH_COMPETITION_SUCCESS } from 'actions/competitionActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE } from 'actions/eventsActions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { SEND_CONTACT_FORM_START } from 'actions/formActions';
import { SEND_VPV } from 'actions/hitTagsActions';
import { FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS } from 'actions/ffr-tv/homeYoutubePlaylistsActions';
import { FETCH_JOB_LIST_SUCCESS, FETCH_JOB_DETAIL_SUCCESS } from 'actions/jobActions';
import { FETCH_LOGE_LIST_SUCCESS, FETCH_LOGE_DETAIL_SUCCESS } from 'actions/logeActions';
import { DISPLAY_MODAL } from 'actions/modalActions';
import {
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_CATEGORIES_SUCCESS,
  FETCH_ARTICLES_BILLBOARD_SUCCESS
} from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS, FETCH_PAGE_DETAIL_SUCCESS } from 'actions/pageActions';
import { FETCH_SEMINAIRE_SUCCESS } from 'actions/seminaireActions';
import { FETCH_STAFFTEAM_SUCCESS } from 'actions/staffActions';
import { FETCH_TAG_ARTICLE_SUCCESS } from 'actions/tagArticleActions';
import { FETCH_USER_PREF_SUCCESS } from 'actions/userActions';

import gtag from 'tracking/ga-gtag';
// import LinkedinPixel from 'tracking/linkedin';
import { numberlyPageView } from 'utils/numberlyUtils';
import { getModalName } from 'utils/tagManagerUtils';
import { FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS } from 'actions/competitionsInternationalesActions';

function pixelTags(keycloakData) {
  const url = window.location;

  // googleAds
  gtag('event', 'conversion', { send_to: 'AW-854380631/lWMJCOWe2qkBENegs5cD' });

  // video 360
  gtag('event', 'conversion', {
    allow_custom_scripts: true,
    u1: `[${url}]`,
    send_to: 'DC-10533957/invmedia/klox_00+standard'
  });

  // facebook pixel
  ReactPixel.pageView();

  // Pixel

  // linkedin
  // LinkedinPixel.pageView();

  // Numberly
  // on ne retraque pas ici les pages déjà traquées par un autre event
  numberlyPageView(keycloakData, window.location.pathname);
}

export default function() {
  return function* hitTagsSaga(): GeneratorType {
    yield all(hitTagsArray.map(hitTag => takeLatest(hitTag, throwHitVirtualPages)));
  };

  function* throwHitVirtualPages(action: hitTagsArrayType): Saga<void> {
    let state = yield select();
    // yield userPref state -> pas ok car bloque la récupération de la valeur et non son fetch
    let userPref = state.userState.userPref;
    let keycloakData = state.userState.keycloakData;
    const getPageListTitle = state => state.pageListState.title;

    if (!state.userState.keycloakData) {
      // fetchUserPref est dispatché depuis keycloakMiddleware
      // on bloque ici jusqu'à son retour en success
      yield take(FETCH_USER_PREF_SUCCESS);

      state = yield select();
      userPref = state.userState.userPref;
      keycloakData = state.userState.keycloakData;
    }

    try {
      const userId = userPref.login ? userPref.login : '';
      const licensee = userPref.licence !== '' ? 'yes' : 'no';
      const licenseeClub = userPref.club_actuel.nom ? userPref.club_actuel.nom : '';
      const licenseeFunctions = userPref.licenceefunctions ? userPref.licenceefunctions : '';
      const newsletterThematic = Object.values(userPref.newsletter).join(', ');
      const newsletterSubscriber = newsletterThematic.length > 0 ? 'yes' : 'no';

      let pageListTitle = yield select(getPageListTitle);

      const url = window.location.pathname.split('/');
      const splitedHref = window.location.href.split('/');

      switch (action.type) {
        case SEND_VPV:
          // private account pages
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: `espace-perso${url[2] ? ` - ${url[2]}` : ''}`,
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_ARTICLE_SUCCESS:
          // /actualites/category-name/article-title
          if (action.payload.analytics) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.article.title,
                articleThematic: action.payload.article.label.name,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          break;
        case FETCH_ARTICLES_SUCCESS:
          // if (window.location.pathname === "/actualites") {
          //   TagManager.dataLayer({
          //     dataLayer: {
          //       'event': 'virtualPageview',
          //       'virtualPageURL' : window.location.pathname,
          //       'virtualPageTitle' : 'Dernières actualités',
          //       'userId' : userId,
          //       'licensee': licensee,
          //       'licenseeClub': licenseeClub,
          //       'licenseeFunction' : licenseeFunctions,
          //       'newsletterSubscriber': newsletterSubscriber,
          //       'newsletterThematic': newsletterThematic
          //     },
          //     dataLayerName: 'dataLayer'
          //   });
          // pixelTags(keycloakData);
          // }
          // else if (window.location.pathname === "/") {
          //   TagManager.dataLayer({
          //     dataLayer: {
          //       'event': 'virtualPageview',
          //       'virtualPageURL' : window.location.pathname,
          //       'virtualPageTitle' : 'Homepage',
          //       'userId' : userId,
          //       'licensee': licensee,
          //       'licenseeClub': licenseeClub,
          //       'licenseeFunction' : licenseeFunctions,
          //       'newsletterSubscriber': newsletterSubscriber,
          //       'newsletterThematic': newsletterThematic
          //     },
          //     dataLayerName: 'dataLayer'
          //   });
          // pixelTags(keycloakData);
          // }
          break;
        case FETCH_ARTICLES_BILLBOARD_SUCCESS:
          // if (window.location.pathname === "/actualites") {
          //   TagManager.dataLayer({
          //     dataLayer: {
          //       'event': 'virtualPageview',
          //       'virtualPageURL' : window.location.pathname,
          //       'virtualPageTitle' : 'Dernières actualités',
          //       'userId' : userId,
          //       'licensee': licensee,
          //       'licenseeClub': licenseeClub,
          //       'licenseeFunction' : licenseeFunctions,
          //       'newsletterSubscriber': newsletterSubscriber,
          //       'newsletterThematic': newsletterThematic
          //     },
          //     dataLayerName: 'dataLayer'
          //   });
          // pixelTags(keycloakData);
          // }
          // else if (window.location.pathname === "/") {
          //   TagManager.dataLayer({
          //     dataLayer: {
          //       'event': 'virtualPageview',
          //       'virtualPageURL' : window.location.pathname,
          //       'virtualPageTitle' : 'Homepage',
          //       'userId' : userId,
          //       'licensee': licensee,
          //       'licenseeClub': licenseeClub,
          //       'licenseeFunction' : licenseeFunctions,
          //       'newsletterSubscriber': newsletterSubscriber,
          //       'newsletterThematic': newsletterThematic
          //     },
          //     dataLayerName: 'dataLayer'
          //   });
          // pixelTags(keycloakData);
          // }
          break;
        case FETCH_ARTICLES_CATEGORY_SUCCESS:
          // /actualites/category-name
          if (url && url.length > 2 && url[1] === 'actualites') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: `Actualités de la categorie ${action.payload.articles[0].label.name}`,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          break;
        case FETCH_TAG_ARTICLE_SUCCESS:
          if (url && url.length > 2 && url[1] === 'tags') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: `Actualités du mot clé #${action.payload.tag.name}`,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          break;
        case FETCH_PAGE_LIST_SUCCESS:
          if (url && url[1] === 'histoires-de-rugby' && url.length !== 2) {
            if (url[2] && url[2] === 'resultats-de-recherche') {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'virtualPageview',
                  virtualPageURL: window.location.pathname,
                  virtualPageTitle: `Histoires de Rugby - Résultats de recherche`,
                  userId: userId,
                  licensee: licensee,
                  licenseeClub: licenseeClub,
                  licenseeFunction: licenseeFunctions,
                  newsletterSubscriber: newsletterSubscriber,
                  newsletterThematic: newsletterThematic
                },
                dataLayerName: 'dataLayer'
              });
              pixelTags(keycloakData);
            }
            break;
          }

          // level 2 pages : /ffr - /equipes-de-france - /jouer-au-rugby - /etre-acteur
          if (url && url.length === 2) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.pageList.title,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }

          // url like /equipe-de-france/rugby-a-xv/xv-de-france/staff?season=439
          // /equipe-de-france/rugby-a-xv/xv-de-france/agenda
          if (
            (url && url.length > 4 && url[1] === 'equipe-de-france' && url[4] === 'agenda') ||
            (splitedHref && splitedHref.length > 6 && splitedHref[6].startsWith('staff?'))
          ) {
            break;
          }

          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: action.payload.pageList.title,
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_PAGE_NODE_SUCCESS:
          if (url && url.length === 3) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.pageNode.title,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }
          break;
        case FETCH_PAGE_DETAIL_SUCCESS:
          // pages like : /ffr/la_federation/presentation_de_la_federation or /ffr/institut-national-de-formation/presentation-generale or /ffr/espace-presse/accreditations
          // or /equipe-de-france/rugby-a-xv/xv-de-france/actualites
          // TODO : fix /equipe-de-france/rugby-a-xv/xv-de-france/ on first render via nav

          if (url && url.length === 4) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.pageDetail.title,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }
          break;
        case FETCH_CLUBS_SUCCESS:
          if (
            window.location.pathname === '/trouver-un-club-de-rugby/' ||
            window.location.pathname === '/trouver-un-club-de-rugby'
          ) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Rechercher un club',
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          } else if (window.location.pathname === '/trouver-un-club-de-rugby/resultats-de-recherche/') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Resultat de la recherche de club',
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          break;
        case FETCH_CLUB_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'eventGA',
              eventCategory: 'page club',
              eventAction: 'consultation',
              eventLabel: action.payload.club.name
            },
            dataLayerName: 'dataLayer'
          });
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: action.payload.club.name,
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_EVENTS_SUCCESS:
          // /evenements
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'Liste des événements',
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_EVENTS_FAILURE:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'Liste des événements',
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_EVENT_SUCCESS:
          if (action.payload.analytics) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: `Détail de l'événement ${action.payload.event.title}`,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          break;
        case SEND_CONTACT_FORM_START:
          const { pathname } = window.location;
          const { formInputs } = action.payload;
          const isSeminaireOrLogeForm =
            formInputs.hasOwnProperty('loge-id') || formInputs.hasOwnProperty('seminaire-id');

          if (!isSeminaireOrLogeForm) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'eventGA',
                eventCategory: 'contact',
                eventAction: 'formulaire envoye',
                eventLabel: pathname
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_FAQ_THEME_SUCCESS:
          // /contact
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: url[1] === 'faq' ? 'FAQ' : 'Contact',
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_COMPETITION_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: `Détail de la compétition ${action.payload.competition.division}`,
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_JOB_LIST_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'Carrières',
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_JOB_DETAIL_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: `Recrutement ${action.payload.details.title}`,
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_LOGE_LIST_SUCCESS:
          if (url && url[1] === 'entreprises' && (url.length === 6 || url.length === 8)) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: `matchs-${url[url.length - 1]}`,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }
          break;
        case FETCH_LOGE_DETAIL_SUCCESS:
          if (url && url[1] === 'entreprises' && url.length === 7) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: action.payload.details.title,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }
          break;
        case FETCH_SEMINAIRE_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: url[url.length - 1],
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS:
          // /ffr-tv
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: 'FFR TV Home',
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case DISPLAY_MODAL:
          const { modalObject } = action.payload;

          if (modalObject === 'MODAL_NEWSLETTER_SUCCESS' && action.payload.content) {
            const analyticsData = action.payload.content ? action.payload.content.analyticsData : '';

            TagManager.dataLayer({
              dataLayer: {
                event: 'eventGA',
                eventCategory: 'inscription_newsletter',
                eventAction: analyticsData
              },
              dataLayerName: 'dataLayer'
            });
          }

          if (modalObject === 'MODAL_CENTENAIRE_FORM_EMAIL') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname + '/popin/partager-votre-histoire-step1',
                virtualPageTitle: 'Centenaire FFR - Partager votre histoire 1/2',
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }

          if (modalObject === 'MODAL_CENTENAIRE_FORM_UPLOAD') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname + '/popin/partager-votre-histoire-step2',
                virtualPageTitle: 'Centenaire FFR - Partager votre histoire 2/2',
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }

          if (modalObject === 'MODAL_CENTENAIRE_FORM_OK') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'eventGA',
                eventCategory: 'page centenaire',
                eventAction: 'popin partager votre histoire',
                eventLabel: 'formulaire soumis'
              },
              dataLayerName: 'dataLayer'
            });
          }

          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: getModalName(modalObject),
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;
        case FETCH_ARTICLES_CATEGORIES_SUCCESS:
          // /actualites
          if (window.location.pathname === '/actualites') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Dernières actualités',
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          // homepage
          else if (window.location.pathname === '/') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: window.location.pathname,
                virtualPageTitle: 'Homepage',
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
          }
          break;
        case FETCH_STAFFTEAM_SUCCESS:
          if (
            splitedHref &&
            splitedHref.length === 7 &&
            splitedHref[3] === 'equipe-de-france' &&
            splitedHref[6] !== 'staff'
          ) {
            const searchParam = splitedHref[6].slice(5);

            TagManager.dataLayer({
              dataLayer: {
                event: 'virtualPageview',
                virtualPageURL: `${window.location.pathname}${searchParam}`,
                virtualPageTitle: pageListTitle,
                userId: userId,
                licensee: licensee,
                licenseeClub: licenseeClub,
                licenseeFunction: licenseeFunctions,
                newsletterSubscriber: newsletterSubscriber,
                newsletterThematic: newsletterThematic
              },
              dataLayerName: 'dataLayer'
            });
            pixelTags(keycloakData);
            break;
          }
          break;

        case FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              event: 'virtualPageview',
              virtualPageURL: window.location.pathname,
              virtualPageTitle: `Détail de la compétition`,
              userId: userId,
              licensee: licensee,
              licenseeClub: licenseeClub,
              licenseeFunction: licenseeFunctions,
              newsletterSubscriber: newsletterSubscriber,
              newsletterThematic: newsletterThematic
            },
            dataLayerName: 'dataLayer'
          });
          pixelTags(keycloakData);
          break;

        default:
      }
    } catch (e) {}
  }
}
