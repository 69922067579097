// @flow
import React, { PureComponent } from 'react';
import Routes from './../../routes';
import * as Sentry from '@sentry/browser';

import Didomi from 'tracking/didomi';

import { withHelmet } from 'infrastructure/seo/Helmet';
import Partners from 'containers/footer/PartnersContainer';
// import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import FacilitiButton from 'components/facilitiButton';
import FacilitiScript from 'components/facilitiButton/script';
// import JoinStories from 'components/fragments/JoinStories';
import Footer from 'components/footer/Footer';
import ModalContainer from 'containers/fragments/modal/ModalContainer';
import type { FullUserType } from 'types/User';
import type { EditProfileValueType } from 'types/User';
import type { FaviconType } from 'types/Settings';
import { numberlyPageView } from 'utils/numberlyUtils';
import { initWeglotCDN, initWeglotInitialize } from 'utils/weglotUtils';
import type { CompetitionsInternationalesLink } from 'types/competitionsInternationales';

import {
  headerBlacklistWithSlugPaths,
  footerBlacklistWithSlugPaths,
  footerBlacklistPaths
} from 'constants/menuConstants';

type HistoryProps = {
  history: any
};

export type StateProps = {
  dashboard_popup: {
    format: string,
    image_desktop: string,
    image_mobile: string
  },
  dashboardConnected: boolean,
  dashboardPopInCount: number,
  error: string,
  favicons: FaviconType,
  keycloakData: any,
  linkPreview: string,
  login_url: string,
  newsletterPopInCount: number,
  newsletterSubscribed: boolean,
  titleLigue: string,
  userPref: FullUserType,
  current_competition: CompetitionsInternationalesLink
};

export type DispatchProps = {
  displayModal: (modalObject: string, content: Object) => void,
  fetchCategories: () => void,
  fetchMenu: () => void,
  fetchPageDetailPreview: (id: number) => void,
  fetchPartners: () => void,
  fetchSettings: () => void,
  increaseNewsletterPopInCount: () => void,
  increaseDashboardPopInCount: () => void,
  keycloakConnect: () => void,
  postUserPref: (action: string, value: EditProfileValueType, token: string) => void
};

type Props = {
  location: any,
  acceptCookies: () => void
} & DispatchProps &
  StateProps &
  HistoryProps;

let TIMEOUT = 3000;
//let INTERVAL = 150000;

const WEGLOT_KEY = 'wg_960e79c0eaaa0c87c35b6962fedd13757';

const FACIL_ITI_KEY = '479d2b1b-c0ea-4a11-8cd0-c74d7ec831ff';

class App extends PureComponent<Props> {
  timeout: TimeoutID;
  interval: IntervalID;
  timeoutDashboard: TimeoutID;
  intervalDashboard: IntervalID;

  displayModalNewsletterIfNotFocused = () => {
    const { displayModal } = this.props;
    if (!window.inputFocused) {
      displayModal('MODAL_NEWSLETTER_OPTIN', {});
    }
  };

  displayModalDashboardIfNotFocused = () => {
    const { displayModal, dashboard_popup, login_url, userPref } = this.props;
    if (!window.inputFocused) {
      if (userPref && userPref.identifie) {
        displayModal('MODAL_DASHBOARD_ACCESS_CONNECTED_IMG', {
          dashboard_popup,
          prenom: userPref.profil && userPref.profil.prenom
        });
      } else {
        displayModal('MODAL_DASHBOARD_ACCESS_NOT_CONNECTED_IMG', { dashboard_popup, login_url });
      }
    }
  };

  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  };

  newsletterModalAutoDisplays = () => {
    /*const { newsletterPopInCount, newsletterSubscribed } = this.props;

    if (newsletterPopInCount < 1 && !newsletterSubscribed && !window.location.href.match('tableau-de-bord')) {
      this.timeout = setTimeout(() => this.displayModalNewsletterIfNotFocused(), TIMEOUT);
    }*/
  };

  dashboardModalAutoDisplays = () => {
    const { dashboardPopInCount, dashboardConnected } = this.props;
    if (
      dashboardPopInCount < 1 &&
      !dashboardConnected &&
      !window.location.href.match('tableau-de-bord') &&
      !window.location.href.match('competitions-internationales')
    ) {
      this.timeoutDashboard = setTimeout(() => this.displayModalDashboardIfNotFocused(), TIMEOUT);
    }
  };

  componentDidMount() {
    const { acceptCookies, keycloakData } = this.props;

    Didomi.install('didomi');

    const inputs = document.querySelectorAll('input');

    if (Object.keys(keycloakData).length > 0) {
      acceptCookies();
      numberlyPageView(keycloakData, window.location.pathname);
    }

    const { pathname } = this.props.location;
    const splited = pathname.split('/');
    if (splited[4] && (splited[4] === 'joueurs' || splited[4] === 'staff')) {
      window.scrollTo(0, 0);
    }

    this.props.keycloakConnect();

    /**
     * Est -on en mode preview
     */
    if (this.props.location.search && /preview=true/.test(this.props.location.search)) {
      const param = this.props.location.search.replace('&preview=true', '');
      if (/\?p=/.test(this.props.location.search)) {
        /**
         * Une actualité
         */
        this.props.history.push('/actualites/preview/post' + param);
        window.location.reload();
      } else if (/\?page_id=/.test(this.props.location.search)) {
        /**
         * Une page
         */
        if (this.props.linkPreview && this.props.location.pathname !== this.props.linkPreview) {
          window.location.href = this.props.linkPreview;
        } else {
          let id = param;
          id = id.replace('?page_id=', '');
          this.props.fetchPageDetailPreview(parseInt(id, 10));
        }
      }
    }

    inputs.forEach(input => {
      input.setAttribute('data-mounted', '1');
      input.addEventListener('focus', this.onInputEvent, false);
      input.addEventListener('blur', this.onInputEvent, false);
    });

    //this.newsletterModalAutoDisplays();
    this.props.fetchSettings();
    this.props.fetchMenu();
    this.props.fetchCategories();
    this.props.fetchPartners();

    // on ne veut display la modal concernant l'espace perso que si keycloak est initialisé
    if (keycloakData && keycloakData.authenticated) {
      this.dashboardModalAutoDisplays();
    }

    // weglot
    const host = window.location.host;
    if (host.includes('localhost') || host.includes('nextmap')) {
      initWeglotCDN(WEGLOT_KEY);
    }

    // facil-iti
    // if (host.includes('localhost') || host.includes('nextmap')) {
    FacilitiScript.init(FACIL_ITI_KEY);
    // }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      //dashboardConnected,
      dashboardPopInCount,
      error,
      keycloakData,
      //newsletterPopInCount,
      //newsletterSubscribed,
      linkPreview,
      userPref: { identifie },
      acceptCookies
    } = this.props;
    const {
      //newsletterPopInCount: prevCount,
      dashboardPopInCount: prevCountDashboard
    } = prevProps;
    const favCategories = localStorage.getItem('favCategories');

    if (favCategories && identifie && error !== 'rest_logged_user') {
      this.props.postUserPref('newsletter-change', favCategories.split(','), this.props.userPref.token);
      localStorage.removeItem('favCategories');
    }

    /*if (newsletterPopInCount > 0 && newsletterPopInCount !== 2 && !newsletterSubscribed) {
      this.interval = setInterval(() => this.displayModalNewsletterIfNotFocused(), INTERVAL);
    };*/

    /*if (dashboardPopInCount > 0 && dashboardPopInCount <= 3 && !dashboardConnected) {
      this.intervalDashboard = setInterval(() => this.displayModalDashboardIfNotFocused(), INTERVAL);
    };*/

    if (prevProps !== this.props) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        const isMounted = input.getAttribute('data-mounted') === '1';
        if (!isMounted) {
          input.setAttribute('data-mounted', '1');
          input.addEventListener('focus', this.onInputEvent, false);
          input.addEventListener('blur', this.onInputEvent, false);
        }
      });
    }

    /*if (prevCount !== newsletterPopInCount && newsletterPopInCount === 1) {
      clearTimeout(this.timeout);
    } else if (prevCount !== newsletterPopInCount && newsletterPopInCount === 2) {
      clearInterval(this.interval);
    }*/

    if (prevCountDashboard !== dashboardPopInCount && dashboardPopInCount >= 15) {
      clearTimeout(this.timeoutDashboard);
    }
    /*else if (prevCountDashboard !== dashboardPopInCount && dashboardPopInCount === 3) {
      clearInterval(this.intervalDashboard);
    }*/

    if (/\?page_id=/.test(this.props.location.search) && linkPreview !== '' && prevProps.linkPreview !== linkPreview) {
      window.location.href = this.props.linkPreview;
    } else {
      const { pathname } = this.props.location;
      const splited = pathname.split('/');
      let behaviour = {
        top: 0,
        left: 0,
        behavior: 'smooth'
      };
      if (splited[1] === 'faq' || splited[1] === 'contact') {
        if (splited[2]) {
          behaviour.top = 250;
        }
      } else if (!splited[3] && splited[2] === 'carriere') {
        if (prevProps.location.pathname === '/ffr/carriere/offres') {
          behaviour.top = 450;
        }
      } else if (!splited[4] && splited[3] && splited[2] === 'carriere') {
        if (prevProps.location.pathname === '/ffr/carriere/offres') {
          behaviour.top = 450;
        }
      } else if (splited[1] === 'evenements') {
        const prevSplited = prevProps.location.pathname.split('/');
        if (prevSplited[1] === 'evenements') {
          behaviour.top = 450;
        }
      } else if (prevProps.location.pathname.split('/')[1] === 'recherche' && splited[1] === 'recherche') {
        behaviour.top = 350;
      } else if (splited[1] === 'competitions-internationales') {
        behaviour = null;
      }

      if (
        window.location.hash &&
        !window.location.hash.match(/&/g) &&
        !window.location.hash.match(/%/g) &&
        !window.location.hash.match(/=/g) &&
        !window.location.hash.match(/:/g)
      ) {
        setTimeout(function() {
          let hash = window.location.hash;

          const anchor = document.querySelector(hash.replace('?', ''));
          if (anchor !== null) {
            anchor.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000);
      } else if (behaviour) {
        setTimeout(function() {
          window.scrollTo(behaviour);
        }, 10);
      }
    }

    // si keycloak est initialisé, on peut afficher la modal de l'espace perso
    if (prevProps.keycloakData.authenticated !== keycloakData.authenticated) {
      this.dashboardModalAutoDisplays();

      acceptCookies();
      numberlyPageView(keycloakData, window.location.pathname);
    }

    // weglot
    const host = window.location.host;
    if (host.includes('localhost') || host.includes('nextmap')) {
      initWeglotInitialize(WEGLOT_KEY);
    }
  }

  renderFooter() {
    if (footerBlacklistPaths.includes(this.props.location.pathname)) return null;
    else if (footerBlacklistWithSlugPaths.includes(this.props.location.pathname.split('/')[1])) return null;
    return (
      <>
        <Partners />
        <Footer />
      </>
    );
  }

  componentDidCatch(error, errorInfo) {
    if (
      error &&
      (error.toString().indexOf('ChunkLoadError') > -1 ||
        error.toString().match('CSS chunk') ||
        (error.name && error.name === 'ChunkLoadError'))
    ) {
      console.log('[ChunkLoadError] Reloading due to error');
      window.location.reload(true);
    } else {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
      if (process.env.NODE_ENV === 'production') {
        this.props.history.push('/404');
        window.location.reload();
      }
    }
  }

  renderHeader() {
    if (headerBlacklistWithSlugPaths.includes(this.props.location.pathname.split('/')[1])) return null;
    return <Header current_competition={this.props.current_competition} />;
  }

  componentWillUnmount() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.removeEventListener('focus', this.onInputEvent, false);
      input.removeEventListener('blur', this.onInputEvent, false);
    });
  }

  render() {
    // const host = window.location.host;

    return (
      <>
        <ModalContainer />
        <FacilitiButton isAside />

        {this.renderHeader()}
        <Routes />
        {this.renderFooter()}

        {/* <CookieContainer acceptCookies={this.props.acceptCookies} /> */}

        {/* {(window.location.hostname === 'localhost' || window.location.hostname === 'pp.website.ffr.nextmap.io') && (
          <JoinStories
            styles={{}} // à voir !!!
            scriptSrc="https://ffr.my.join-stories.com/widgets/boutique-officielle/index.js"
            scriptWidgetId="solo-sticky-boutique-officielle"
            scriptWidgetAlias="boutique-officielle"
          />
        )} */}
      </>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate={`%s`} defaultTitle={pageProps.titleLigue ? pageProps.titleLigue : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['favicon-32']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);
