// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import DidomiCookie from 'components/Didomi/DidomiCookieConsent';
import FacilitiButton from 'components/facilitiButton';
import FooterSelect from 'components/footer/FooterSelect';
import type { MenuElementType } from 'types/MenuElement';

import './FooterBottomStyle.css';

type Props = {
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  footerElements: MenuElementType[],
  logo_footer: string,
  title: string
};

class FooterBottom extends PureComponent<Props> {
  static defaultProps = {
    footerElements: []
  };

  renderFooterCategory = () => {
    const { footerElements } = this.props;

    const categories = [];
    let categoriesCount = 0;
    footerElements.forEach((footerCategory, index) => {
      categories[categoriesCount] = categories[categoriesCount] || [];
      categories[categoriesCount].push(<FooterSelect key={index} footerCategory={footerCategory} />);
      if ((index + 1) % 4 === 0) {
        // the 4 here is how many items you want per row
        // if we can perfectly divide the current position by 4
        // we have filled a row, so now increment the row
        categoriesCount++;
      }
    });
    return categories;
  };

  render() {
    const {
      contacts: { adresse, adresse_map, telephone },
      logo_footer,
      title
    } = this.props;
    const categories = this.renderFooterCategory();
    const capitalizedAddress = `${
      adresse
        ? adresse
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : ''
    }`;
    const splitedAddress = capitalizedAddress.split('<br />');
    const internationalPhoneNumber =
      telephone && telephone.slice(0, 1) === '0' ? `+33 ${telephone.substr(1)}` : telephone ? telephone : false;

    return (
      <>
        <div className="container footer__links">
          <div className="footer__contact-detail">
            <div className="footer__address">
              <i className="icon icon-place"></i>
              <div className="footer__address-detail">
                {adresse && (
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${adresse_map ? adresse_map : adresse}`}
                    title={`Visualiser l'adresse de la fédération`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {adresse && splitedAddress.map(addressPart => <p key={addressPart}>{addressPart}</p>)}
                  </a>
                )}
              </div>
            </div>
            {internationalPhoneNumber && (
              <div className="footer__phone-number">
                <i className="icon icon-phone"></i>
                <p>
                  <a href={`tel:${internationalPhoneNumber}`}>{internationalPhoneNumber}</a>
                </p>
              </div>
            )}
          </div>

          <div>
            {categories.map((ar, i) => (
              <div key={i} className="row">
                {' '}
                {ar}{' '}
              </div>
            ))}
            <div className="footer__logo mt-3 mt-lg-0">
              <img src={logo_footer} alt={`Logo officiel de la ${title}`} style={{ filter: 'grayscale(100%)' }} />
            </div>
          </div>
        </div>

        <div className="footer__corporate">
          <div className="container">
            <div className="row">
              <div className="col col-lg-8">
                <ul className="footer__corporate-links">
                  <li>
                    <Link to="/contact" title={`Contacter la ${title}`}>
                      Nous contacter
                    </Link>
                  </li>
                  <li>
                    <Link to="/cgu" title={`Consulter les conditions générales d'utilisation de la ${title}`}>
                      CGU
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/donnees-personnelles"
                      title={`Consulter les informations sur la politique de confidentialité de la ${title}`}
                    >
                      Politique de confidentialité
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to="/ffr-websites-use-policies" title={`Read the FFR websites use policies`}>FFR Web Sites Use Policies</Link>
                  </li>
                  */}
                  <li>
                    <Link to="/faq" title={`Consulter la Foire Aux Questions de la ${title}`}>
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/mentions-legales" title={`Consulter les mentions légales de la ${title}`}>
                      Mentions légales
                    </Link>
                  </li>
                  <li>
                    <DidomiCookie />
                  </li>
                  <li>
                    <FacilitiButton isFooter />
                  </li>
                </ul>
              </div>
              <div className="col col-lg-4">
                <p className="footer__corporate-legal">
                  © 2018-{new Date().getFullYear()} {title}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FooterBottom;
